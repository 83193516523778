import axios from 'axios'
import config from '@/services/config'

export default {

  createProposal(data) {
    return axios.post(`${config.urlCore}/api/v3/profile/proposal/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        console.log(error)
        return error.response
      })
  },
  updateProposal(proposal_uuid, data) {
    return axios.post(`${config.urlCore}/api/v3/profile/proposal/${proposal_uuid}`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  addOptionOrRequest(proposal_uuid, data, element) {
    return axios.post(`${config.urlCore}/api/v3/profile/proposal/${proposal_uuid}/add/${element}/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  getProposals() {
    return axios.get(`${config.urlCore}/api/v3/profile/get/proposals/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  getDetailProfiles(network, username) {
    return axios.get(`${config.urlCore}/api/v3/profile/${network}/${username}/detail/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  detailProposal(proposal_uuid) {
    return axios.get(`${config.urlCore}/api/v3/profile/proposal/${proposal_uuid}/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  deleteElement(proposal_uuid, element_uuid, element) {
    return axios.delete(`${config.urlCore}/api/v3/profile/proposal/${proposal_uuid}/${element}/${element_uuid}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  uploadImagesProposal(proposal_uuid, data) {
    return axios.post(`${config.urlCore}/api/v3/profile/proposal/add/images/${proposal_uuid}/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  searchProposals(data) {
    return axios.post(`${config.urlCore}/api/v3/profile/search/proposals/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  updateElement(proposal_uuid, element_uuid, data, element) {
    return axios.post(`${config.urlCore}/api/v3/profile/proposal/${proposal_uuid}/${element}/${element_uuid}/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  }
}