<template>
  <layout-stepper>
    <template #body>
      <b-link @click="$router.push({name: 'step_add_connection'})" v-if="!has_slug">
        <feather-icon icon="ChevronLeftIcon"></feather-icon>
        Previous Step
      </b-link>
      <div class="d-flex justify-content-center">
        <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
          <b-col class="col-12 text-center">
            <h1 class="mb-1">Give your listing a title.</h1>
            <p>This is your best chance to make your listing stand out in the marketplace. Keep it short - 10 to 12 words - but try to catch people's attention. What are you offering? Why should they click to see more?</p>
     
            <b-form-input v-model="title" class="mt-2"></b-form-input>

            <b-col class="col-12 d-flex justify-content-center">
              <b-button variant="primary" class="my-2" :disabled="title === ''" @click="save()">{{has_slug ? 'Save and preview' : 'Next step'}}</b-button>
            </b-col>
          </b-col>
        </b-row>
      </div>
    </template>
  </layout-stepper>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BLink,
  BFormInput,
} from 'bootstrap-vue';
import layoutStepper from '@/views/components/creator/layoutStepper.vue';
import utils from '@/libs/utils';
import creator_services from '@/services/creator';

export default {
  name: 'stepTitle',
  components: {
    BRow,
    BCol,
    BButton,
    BLink,
    BFormInput,
    layoutStepper,
  },
  data() {
    return {
      title: '',
      profile: {},
      utils,
      steps: null,
      proposal: {}
    }
  },
  created() {
    this.getData();
  },
  computed: {
    has_slug() {
      return this.$route.params.slug
    }
  },
  methods: {
    getData() {
      if (this.$route.params.slug !== undefined) { 
        creator_services.detailProposal(this.$route.params.slug).then((response) => {
          this.title = response.title;
          this.proposal = response;
        })
      } else {
        this.steps = JSON.parse(localStorage.getItem('steps'));
        if (this.steps.title && this.steps.title !== `@${this.steps.network_selected.username}`) this.title = this.steps.title;
        else this.title = `@${this.steps.network_selected.username}`;
      }
    },
    save() {
      if (this.$route.params.slug !== undefined) { 
        creator_services.updateProposal(this.proposal.uuid, {title: this.title}).then(() => {
          this.$router.push({name: 'step_preview', params: {slug: this.proposal.slug}})
        })
      } else {
        this.steps['title'] = this.title;
        localStorage.setItem('steps', JSON.stringify(this.steps));
        this.$router.push({name: 'step_description'});
      }
    },
  }
}
</script>